import { AdminState } from './state';

export const getters = {
  adminUsers: (state: AdminState) => state.users,
  adminOneUser: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  services: (state: AdminState) => state.services,
  oneService: (state: AdminState) => (serviceId: number) => {
    const filteredServices = state.services.filter((service) => service.id === serviceId);
    if (filteredServices.length > 0) {
      return { ...filteredServices[0] };
    }
  },
  moSenderBans: (state: AdminState) => state.moSenderBans,
  settings: (state: AdminState) => state.settings,
};
