import Vue from 'vue';

const TOKEN_MIN_VALIDITY_SECONDS = 70;

export async function updateToken() {
  await (Vue as any).$keycloak.updateToken(TOKEN_MIN_VALIDITY_SECONDS);
  return (Vue as any).$keycloak.token;
}

export async function getUserInfo() {
  const info = await (Vue as any).$keycloak.loadUserInfo();
  return info;
}

export function getUserRoles() {
  const roles = (Vue as any).$keycloak.realmAccess.roles;
  return roles;
}
