import { api } from '@/api';
import router from '@/router';
import { ActionContext } from 'vuex';
import {
  commitSetLoggedIn,
  commitSetLogInError,
  dispatchGetUserProfile,
  dispatchRouteLoggedIn,
  commitSetUserProfile,
  dispatchCheckApiError,
  commitRemoveNotification,
  commitAddNotification,
  commitSetNotifications,
} from './accessors';
import { AxiosError } from 'axios';
import { State } from '../state';
import { MainState, AppNotification } from './state';
import {
  IMessageCreate,
  INewsletter,
  INewsletterCreate,
  INewsletterMessage,
  INewsletterSearchParams,
  IBlacklistSearchParams,
  IBlacklistCreate,
  IBlacklistAddress,
  IBlacklist,
} from '@/interfaces';
import {
  ISeqSendsSearchParams,
  ISeqSendCreate,
  ISeqSendStepCreate,
  ISeqSend,
  ISeqSendStep,
} from '@/interfaces/seq_sends';
import { IGetItemsResponse } from '@/interfaces/api';

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogIn(context: MainContext, payload: { username: string; password: string }) {
    try {
      commitSetLoggedIn(context, true);
      commitSetLogInError(context, false);
      await dispatchGetUserProfile(context);
      await dispatchRouteLoggedIn(context);
      commitAddNotification(context, { content: 'Logged in', color: 'success' });
    } catch (err) {
      commitSetLogInError(context, true);
    }
  },
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await api.getMe();
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateMessage(context: MainContext, payload: IMessageCreate) {
    try {
      const loadingNotification = { content: 'sending message', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createMessage(context.state.token, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Message successfully sent', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionCreateNewslatter(context: MainContext, payload: INewsletterCreate): Promise<INewsletter> {
    try {
      const loadingNotification = { content: 'sending newsletter', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createNewsletter(context.state.token, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Newsletter successfully sent', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },
  async actionCreateNewslatterMessages(context: MainContext, payload: { id: number; body: INewsletterMessage[] }) {
    try {
      const loadingNotification = { content: 'creating newsletter messages', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createNewsletterMessages(context.state.token, payload.id, payload.body);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Newsletter messages successfully created', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionGetNewsletter(context: MainContext, id: number): Promise<INewsletter> {
    try {
      const response = await api.getNewsletter(context.rootState.main.token, id);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionGetNewslettersList(
    context: MainContext,
    data: INewsletterSearchParams,
  ): Promise<IGetItemsResponse<INewsletter>> {
    try {
      const response = await api.getNewslettersList(
        context.rootState.main.token,
        data.skip,
        data.limit,
        data.orderBy,
        data.descending,
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionGetNewsletterMessages(
    context: MainContext,
    data: INewsletterSearchParams,
  ): Promise<IGetItemsResponse<INewsletterMessage>> {
    try {
      const response = await api.getNewsletterMessages(
        context.rootState.main.token,
        data.newsletterId,
        data.skip,
        data.limit,
        data.orderBy,
        data.descending,
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionUpdateNewsletter(context: MainContext, data: INewsletter): Promise<INewsletter> {
    try {
      const loadingNotification = { content: 'update newsletter', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.updateNewsletter(context.state.token, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'The newsletter is updated', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionDeleteNewsletter(context: MainContext, id: number) {
    try {
      const response = await api.deleteNewsletters(context.rootState.main.token, id);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionUploadNewsletterReceiversFile(context: MainContext, payload: { id: number; file: File }) {
    try {
      const loadingNotification = { content: 'uploading receivers file', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.uploadNewsletterReceiversFile(context.state.token, payload.id, payload.file);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'File uploaded', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionGetBlacklist(context: MainContext, id: number): Promise<IBlacklist> {
    try {
      const response = await api.getBlacklist(context.rootState.main.token, id);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionGetBlacklists(
    context: MainContext,
    data: IBlacklistSearchParams,
  ): Promise<IGetItemsResponse<IBlacklist>> {
    try {
      const response = await api.getBlacklists(
        context.rootState.main.token,
        data.skip,
        data.limit,
        data.orderBy,
        data.descending,
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionGetBlacklistAddresses(
    context: MainContext,
    data: IBlacklistSearchParams,
  ): Promise<IGetItemsResponse<IBlacklistAddress>> {
    try {
      const response = await api.getBlacklistAddresses(
        context.rootState.main.token,
        data.blacklistId,
        data.skip,
        data.limit,
        data.orderBy,
        data.descending,
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionUpdateBlacklist(context: MainContext, data: IBlacklist): Promise<IBlacklist> {
    try {
      const loadingNotification = { content: 'update blacklist', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.updateBlacklist(context.state.token, data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'The blacklist is updated', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionCreateBlacklist(context: MainContext, payload: IBlacklistCreate): Promise<IBlacklist> {
    try {
      const loadingNotification = { content: 'creating a blacklist', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createBlacklist(context.state.token, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'The balcklist successfully created', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionCreateBlacklistAddresses(context: MainContext, payload: { id: number; body: IBlacklistAddress[] }) {
    try {
      const loadingNotification = { content: 'creating blacklist addresses', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createBlacklistAddresses(context.state.token, payload.id, payload.body);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Blacklist addresses were created', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionUploadBlacklistAddressesFile(context: MainContext, payload: { id: number; file: File }) {
    try {
      const loadingNotification = { content: 'uploading blacklist file', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.uploadBlacklistAddressesFile(context.state.token, payload.id, payload.file);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'File uploaded', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionDeleteBlacklist(context: MainContext, id: number) {
    try {
      const response = await api.deleteBlacklist(context.rootState.main.token, id);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionDeleteBlacklistAddresses(context: MainContext, payload: { id: number; payload: string[] }) {
    try {
      const response = await api.deleteBlacklistAddresses(context.rootState.main.token, payload);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionGetSeqSends(context: MainContext, params: ISeqSendsSearchParams): Promise<IGetItemsResponse<ISeqSend>> {
    try {
      const response = await api.getSeqSends(
        context.rootState.main.token,
        params.skip,
        params.limit,
        params.orderBy,
        params.descending,
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionGetSeqSendItem(context: MainContext, id: number): Promise<ISeqSend> {
    try {
      const response = await api.getSeqSendItem(context.rootState.main.token, id);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionGetSeqSendSteps(context: MainContext, id: number): Promise<IGetItemsResponse<ISeqSendStep>> {
    try {
      const response = await api.getSeqSendSteps(context.rootState.main.token, id);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionCreateSeqSend(context: MainContext, payload: ISeqSendCreate): Promise<ISeqSend> {
    try {
      const loadingNotification = { content: 'creating a seq-send', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createSeqSend(context.state.token, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'The seq-send successfully created', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionUpdateSeqSend(context: MainContext, payload: ISeqSend): Promise<ISeqSend> {
    try {
      const loadingNotification = { content: 'creating a seq-send', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.updateSeqSend(context.state.token, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'The seq-send successfully created', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionCreateSeqSendStep(
    context: MainContext,
    payload: { id: number; body: ISeqSendStepCreate },
  ): Promise<ISeqSendStep> {
    try {
      const loadingNotification = { content: 'creating a seq-send', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.createSeqSendStep(context.state.token, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'The seq-send successfully created', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionUpdateSeqSendStep(
    context: MainContext,
    payload: { id: number; body: ISeqSendStep },
  ): Promise<ISeqSendStep> {
    try {
      const loadingNotification = { content: 'updating a seq-send', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.updateSeqSendStep(context.state.token, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'The seq-send successfully updated', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionSeqSendStepOrderChange(
    context: MainContext,
    payload: { id: number; seqStepIds: number[] },
  ): Promise<ISeqSendStep> {
    try {
      const loadingNotification = { content: 'creating a seq-send', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.changeSeqSendStepOrder(context.state.token, payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'The seq-send successfully created', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      throw error;
    }
  },

  async actionDeleteSeqSend(context: MainContext, id: number) {
    try {
      const response = await api.deleteSeqSend(context.rootState.main.token, id);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionDeleteSeqSendStep(context: MainContext, payload: { id: number; stepId: number }) {
    try {
      const response = await api.deleteSeqSendStep(context.rootState.main.token, payload);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionUploadReceiversFile(context: MainContext, file: File) {
    try {
      const loadingNotification = { content: 'uploading receivers file', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.uploadReceiversFile(context.state.token, file);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'File uploaded', color: 'success' });
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateMe(context.state.token, payload),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Profile successfully updated', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    try{
      const response = await api.getMe();
      commitSetLoggedIn(context, true);
      commitSetUserProfile(context, response.data);
      } catch(error){
      setTimeout(async () => {
        const response = await api.getMe();
        commitSetLoggedIn(context, true);
        commitSetUserProfile(context, response.data);
      }, 3000);
    }
  },
  async actionCheckApiError(context: MainContext, payload: AxiosError) {
    if (payload?.response?.data?.hasOwnProperty('detail')) {
      commitSetNotifications(context, [{ content: payload.response!.data.detail, color: 'error' }]);
    } else {
      commitSetNotifications(context, [{ content: 'An error occurred while processing your request', color: 'error' }]);
    }
  },
  actionRouteLoggedIn(context: MainContext) {
    if (router.currentRoute.path === '/') {
      router.push('/main');
    }
  },
  async removeNotification(context: MainContext, payload: { notification: AppNotification; timeout: number }) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
};
