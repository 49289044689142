import { getStoreAccessors } from 'typesafe-vuex';
import { MainState } from '../state';
import { State } from '@/store/state';
import { mutations } from '../mutations';

const { commit } = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetNotifications = commit(mutations.setNotifications);
export const commitSetLang = commit(mutations.setLang);
