import axios, { AxiosResponse } from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IServiceCreate,
  IService,
  IServiceUpdate,
  IMessageCreate,
  ISenderBans,
  ISenderBanCreate,
  INewsletter,
  INewsletterCreate,
  INewsletterMessage,
  IBlacklistAddress,
  IBlacklistCreate,
  IBlacklist,
} from './interfaces';
import { ISetting } from '@/interfaces/setting';
import { ISeqSend, ISeqSendStep, ISeqSendCreate, ISeqSendStepCreate } from '@/interfaces/seq_sends';

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe() {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`);
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.patch<IUserProfile>(`${apiUrl}/api/v1/users/me`, data);
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`);
  },
  async getUser(token: string, id: number) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/${id}`);
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.patch(`${apiUrl}/api/v1/users/${userId}`, data);
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data);
  },
  async deleteUser(token: string, userId: number) {
    return axios.delete(`${apiUrl}/api/v1/users/${userId}`);
  },
  async deleteUsers(token: string, usersIds: number[]) {
    return axios.delete(`${apiUrl}/api/v1/users/delete/`, { data: usersIds });
  },

  async getMessagesStatistics(token: string, fromDate: string, toDate: string) {
    return axios.get(`${apiUrl}/api/v1/messages/statistics/`, {
      params: { from_date: fromDate, to_date: toDate },
    });
  },
  async getMessagesByDay(token: string, fromDate: string, toDate: string) {
    return axios.get(`${apiUrl}/api/v1/messages/by_day/`, {
      params: { from_date: fromDate, to_date: toDate },
    });
  },
  async createMessage(token: string, data: IMessageCreate) {
    return axios.post(`${apiUrl}/api/v1/messages/`, data);
  },
  async uploadReceiversFile(token: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${apiUrl}/api/v1/messages/upload_receivers_file/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  async getMessages(
    token: string,
    fromDate: string | null,
    toDate: string | null,
    receiver: string | null,
    sender: string | null,
    messageId: string | null,
    skip: number,
    limit: number,
    orderBy: string,
    descending: boolean,
  ) {
    return axios.get(`${apiUrl}/api/v1/messages/`, {
      params: {
        from_date: fromDate,
        to_date: toDate,
        receiver,
        sender,
        message_id: messageId,
        skip,
        limit,
        order_by: orderBy,
        descending,
      },
    });
  },

  async getNewsletter(token: string, id: number): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/newsletters/${id}`);
  },

  async getNewslettersList(
    token: string,
    skip: number,
    limit: number,
    orderBy: string,
    descending: boolean,
  ): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/newsletters/`, {
      params: {
        skip,
        limit,
        order_by: orderBy,
        descending,
      },
    });
  },

  async createNewsletter(token: string, data: INewsletterCreate): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/newsletters/`, data);
  },

  async updateNewsletter(token: string, { id, name }: INewsletter): Promise<AxiosResponse> {
    return axios.patch(`${apiUrl}/api/v1/newsletters/${id}`, { name });
  },

  async uploadNewsletterReceiversFile(token: string, newsletterId: number, file: File): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(`${apiUrl}/api/v1/newsletters/${newsletterId}/messages/from-file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  async createNewsletterMessages(
    token: string,
    newsletterId: number,
    data: INewsletterMessage[],
  ): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/newsletters/${newsletterId}/messages/bulk-create`, { items: data });
  },

  async getNewsletterMessages(
    token: string,
    newsletterId: string | null,
    skip: number,
    limit: number,
    orderBy: string,
    descending: boolean,
  ): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/newsletters/${newsletterId}/messages/`, {
      params: {
        newsletter_id: newsletterId,
        skip,
        limit,
        order_by: orderBy,
        descending,
      },
    });
  },

  async deleteNewsletters(token: string, newsletterId: number): Promise<AxiosResponse> {
    return axios.delete(`${apiUrl}/api/v1/newsletters/${newsletterId}`);
  },

  async getBlacklist(token: string, id: number): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/blacklists/${id}`);
  },

  async getBlacklists(
    token: string,
    skip: number,
    limit: number,
    orderBy: string,
    descending: boolean,
  ): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/blacklists/`, {
      params: {
        skip,
        limit,
        order_by: orderBy,
        descending,
      },
    });
  },

  async getBlacklistAddresses(
    token: string,
    blacklitsId: string | null,
    skip: number,
    limit: number,
    orderBy: string,
    descending: boolean,
  ): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/blacklists/${blacklitsId}/addresses/`, {
      params: {
        blacklits_id: blacklitsId,
        skip,
        limit,
        order_by: orderBy,
        descending,
      },
    });
  },

  async updateBlacklist(token: string, { id, name }: IBlacklist): Promise<AxiosResponse> {
    return axios.patch(`${apiUrl}/api/v1/blacklists/${id}`, { name });
  },

  async createBlacklist(token: string, data: IBlacklistCreate): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/blacklists/`, data);
  },

  async createBlacklistAddresses(
    token: string,
    blacklistId: number,
    data: IBlacklistAddress[],
  ): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/blacklists/${blacklistId}/addresses/bulk-create`, { items: data });
  },

  async uploadBlacklistAddressesFile(token: string, blacklistId: number, file: File): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(`${apiUrl}/api/v1/blacklists/${blacklistId}/addresses/from-file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  async deleteBlacklist(token: string, blacklistId: number): Promise<AxiosResponse> {
    return axios.delete(`${apiUrl}/api/v1/blacklists/${blacklistId}`);
  },

  async deleteBlacklistAddresses(token: string, { id, payload }): Promise<AxiosResponse> {
    return axios.delete(`${apiUrl}/api/v1/blacklists/${id}/addresses/bulk-delete`, {
      data: payload,
    });
  },

  async getSeqSends(
    token: string,
    skip: number,
    limit: number,
    orderBy: string,
    descending: boolean,
  ): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/seq-sends/`, {
      params: {
        skip,
        limit,
        order_by: orderBy,
        descending,
      },
      //
    });
  },

  async getSeqSendItem(token: string, id: number): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/seq-sends/${id}`);
  },

  async getSeqSendSteps(token: string, id: number): Promise<AxiosResponse> {
    return axios.get(`${apiUrl}/api/v1/seq-sends/${id}/steps/`);
  },

  async createSeqSend(token: string, data: ISeqSendCreate): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/seq-sends/`, data);
  },

  async updateSeqSend(token: string, { id, name }: ISeqSend): Promise<AxiosResponse> {
    return axios.patch(`${apiUrl}/api/v1/seq-sends/${id}`, { name });
  },

  async createSeqSendStep(
    token: string,
    { id, body }: { id: number; body: ISeqSendStepCreate },
  ): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/seq-sends/${id}/steps/`, body);
  },

  async updateSeqSendStep(token: string, { id, body }: { id: number; body: ISeqSendStep }): Promise<AxiosResponse> {
    const { id: stepId, sender, ttl }: ISeqSendStep = body;
    return axios.patch(`${apiUrl}/api/v1/seq-sends/${id}/steps/${stepId}`, { sender, ttl });
  },

  async changeSeqSendStepOrder(
    token: string,
    { id, seqStepIds }: { id: number; seqStepIds: number[] },
  ): Promise<AxiosResponse> {
    return axios.post(`${apiUrl}/api/v1/seq-sends/${id}/steps/update-order`, seqStepIds);
  },

  async deleteSeqSend(token: string, seqSendId: number): Promise<AxiosResponse> {
    return axios.delete(`${apiUrl}/api/v1/seq-sends/${seqSendId}`);
  },

  async deleteSeqSendStep(token: string, { id, stepId }: { id: number; stepId: number }): Promise<AxiosResponse> {
    return axios.delete(`${apiUrl}/api/v1/seq-sends/${id}/steps/${stepId}`);
  },

  async createService(token: string, data: IServiceCreate) {
    return axios.post(`${apiUrl}/api/v1/services/`, data);
  },
  async getService(token: string, id: number) {
    return axios.get<IService>(`${apiUrl}/api/v1/services/${id}`);
  },
  async getServices(token: string) {
    return axios.get<IService[]>(`${apiUrl}/api/v1/services/`);
  },
  async updateService(token: string, serviceId: number, data: IServiceUpdate) {
    return axios.patch(`${apiUrl}/api/v1/services/${serviceId}`, data);
  },
  async deleteService(token: string, serviceId: number) {
    return axios.delete(`${apiUrl}/api/v1/services/${serviceId}`);
  },
  async deleteServices(token: string, serviceIds: number[]) {
    return axios.delete(`${apiUrl}/api/v1/services/delete/`, { data: serviceIds });
  },

  async getSenderBans(token: string, skip: number, limit: number, sender: string) {
    return axios.get<ISenderBans>(`${apiUrl}/api/v1/mo_sender_bans/`, {
      params: { skip, limit, sender },
    });
  },
  async deleteSenderBan(token: string, sender: string) {
    return axios.delete(`${apiUrl}/api/v1/mo_sender_bans/${sender}`);
  },
  async deleteSenderBans(token: string, senders: string[]) {
    return axios.delete(`${apiUrl}/api/v1/mo_sender_bans/delete/`, { data: senders });
  },
  async createSenderBan(token: string, data: ISenderBanCreate) {
    return axios.post(`${apiUrl}/api/v1/mo_sender_bans/`, data);
  },

  async getSettings(token: string) {
    return axios.get<ISetting[]>(`${apiUrl}/api/v1/settings/`);
  },
  async createSettings(token: string, data: ISetting[]) {
    return axios.post(`${apiUrl}/api/v1/settings/`, data);
  },
};
