import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterComponent from '@/components/RouterComponent.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "start" */ '../views/main/Start.vue'),
    children: [
      {
        path: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
      },
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "main" */ '../views/main/Main.vue'),
        children: [
          {
            path: 'dashboard',
            component: () => import(/* webpackChunkName: "main-dashboard" */ '../views/main/Dashboard.vue'),
          },
          {
            path: 'profile',
            component: RouterComponent,
            redirect: 'profile/view',
            children: [
              {
                path: 'view',
                component: () => import(/* webpackChunkName: "main-profile" */ '../views/main/profile/UserProfile.vue'),
              },
              {
                path: 'edit',
                component: () =>
                  import(/* webpackChunkName: "main-profile-edit" */ '../views/main/profile/UserProfileEdit.vue'),
              },
              {
                path: 'password',
                component: () =>
                  import(/* "main-profile-password" */ '../views/main/profile/UserProfileEditPassword.vue'),
              },
            ],
          },
          {
            path: 'blacklists',
            component: RouterComponent,
            redirect: 'blacklists/all',
            children: [
              {
                path: 'create',
                name: 'main-blacklists-create',
                component: () =>
                  import(
                    /* webpackChunkName: "main-blacklists-create" */ '../views/main/blacklist/CreateBlacklist.vue'
                  ),
              },
              {
                path: 'all',
                name: 'main-blacklists-all',
                component: () =>
                  import(/* webpackChunkName: "main-blacklists-all" */ '../views/main/blacklist/Blacklists.vue'),
              },
              {
                path: 'edit/:id',
                name: 'main-blacklists-edit',
                component: () =>
                  import(/* webpackChunkName: "main-blacklists-edit" */ '../views/main/blacklist/EditBlacklist.vue'),
              },
              {
                path: ':id/addresses',
                name: 'main-blacklist-addresses-all',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "main-blacklists-addresses-all" */ '../views/main/blacklist/BlacklistAddresses.vue'
                  ),
              },
            ],
          },
          {
            path: 'messages',
            component: RouterComponent,
            redirect: 'messages/all',
            children: [
              {
                path: 'statistics',
                name: 'main-messages-statistics',
                component: () => import('../views/main/message/MessagesStatistics.vue'),
              },
              {
                path: 'send',
                name: 'main-messages-send',
                component: () => import('../views/main/message/SendMessage.vue'),
              },
              {
                path: 'all',
                component: () => import('../views/main/message/Messages.vue'),
              },
            ],
          },
          {
            path: 'newsletter',
            component: RouterComponent,
            redirect: 'newsletter/all',
            children: [
              {
                path: 'send',
                name: 'main-newsletter-send',
                component: () =>
                  import(/* webpackChunkName: "main-newsletter-send" */ '../views/main/newsletter/RunNewsletter.vue'),
              },
              {
                path: 'all',
                name: 'main-newsletter-all',
                component: () =>
                  import(/* webpackChunkName: "main-newsletter-all" */ '../views/main/newsletter/Newsletters.vue'),
              },
              {
                path: 'edit/:id',
                name: 'main-newsletter-edit',
                component: () =>
                  import(/* webpackChunkName: "main-newsletter-edit" */ '../views/main/newsletter/EditNewsletter.vue'),
              },
              {
                path: ':id/messages',
                name: 'main-newsletter-messages-all',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "main-newsletter-messages-all" */ '../views/main/newsletter/NewslettersMessages.vue'
                  ),
              },
            ],
          },
          {
            path: 'seq-sends',
            component: RouterComponent,
            redirect: 'seq-sends/all',
            children: [
              {
                path: 'all',
                name: 'main-seq-sends-all',
                component: () =>
                  import(/* webpackChunkName: "main-seq-sends-all" */ '../views/main/seq_sends/SeqSends.vue'),
              },
              {
                path: 'create',
                name: 'main-seq-sends-create',
                component: () =>
                  import(/* webpackChunkName: "main-seq-sends-create" */ '../views/main/seq_sends/SeqSendCreate.vue'),
              },
              {
                path: 'edit/:id',
                name: 'main-seq-sends-edit',
                props: { default: true, isEditMode: true },
                component: () =>
                  import(/* webpackChunkName: "main-seq-sends-edit" */ '../views/main/seq_sends/SeqSendEdit.vue'),
              },
            ],
          },
          {
            path: 'admin',
            component: () => import(/* webpackChunkName: "main-admin" */ '../views/main/admin/Admin.vue'),
            redirect: 'admin/users/all',
            children: [
              {
                path: 'users',
                redirect: 'users/all',
              },
              {
                path: 'users/all',
                component: () => import('../views/main/admin/AdminUsers.vue'),
              },
              {
                path: 'users/edit/:id',
                name: 'main-admin-users-edit',
                component: () =>
                  import(/* webpackChunkName: "main-admin-users-edit" */ '../views/main/admin/EditUser.vue'),
              },
              {
                path: 'users/create',
                name: 'main-admin-users-create',
                component: () =>
                  import(/* webpackChunkName: "main-admin-users-create" */ '../views/main/admin/CreateUser.vue'),
              },
              {
                path: 'services',
                redirect: 'services/all',
              },
              {
                path: 'services/all',
                component: () =>
                  import(/* webpackChunkName: "main-admin-services" */ '../views/main/service/Services.vue'),
              },
              {
                path: 'services/create',
                name: 'main-admin-services-create',
                component: () => import(/* "main-admin-services-create" */ '../views/main/service/CreateService.vue'),
              },
              {
                path: 'services/edit/:id',
                name: 'main-admin-services-edit',
                component: () =>
                  import(/* webpackChunkName: "main-admin-services-edit" */ '../views/main/service/EditService.vue'),
              },
              {
                path: 'settings',
                redirect: 'settings/all',
              },
              {
                path: 'settings/all',
                component: () =>
                  import(/* webpackChunkName: "main-admin-services" */ '../views/main/setting/Settings.vue'),
              },
              {
                path: 'mo_sender_bans',
                redirect: 'mo_sender_bans/all',
              },
              {
                path: 'mo_sender_bans/all',
                component: () =>
                  import(/* webpackChunkName: "main-admin-services" */ '../views/main/sender_ban/SenderBans.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
