import Vue, { PluginObject } from 'vue';
import Keycloak, { KeycloakConfig } from 'keycloak-js';

const initOptions: KeycloakConfig = {
  url: window.VUE_APP_KEYCLOAK_URL,
  realm: window.VUE_APP_REALM,
  clientId: window.VUE_APP_CLIENTID,
};

const _keycloak = Keycloak(initOptions);

const Plugin: PluginObject<any> = {
  install: (Vue) => {
    (Vue as any).$keycloak = _keycloak;
  },
};

Plugin.install = (Vue) => {
  (Vue as any).$keycloak = _keycloak;
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
