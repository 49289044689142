import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors';

import ru from '@/locale/ru';
import en from '@/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.green.darken2,
        secondary: colors.grey.darken4,
      },
    },
  },
  icons: {
    iconfont: 'md', // default - only for display purposes
  },
  lang: {
    locales: { en, ru },
    current: localStorage.getItem('lang') || 'en',
  },
});
