import { ISenderBan, IService, IUserProfile } from '@/interfaces';
import { AdminState } from './state';
import Admin from '@/views/main/admin/Admin.vue';
import { ISetting } from '@/interfaces/setting';

export const mutations = {
  setUsers(state: AdminState, payload: IUserProfile[]) {
    state.users = payload;
  },
  setUser(state: AdminState, payload: IUserProfile) {
    const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
    users.push(payload);
    state.users = users;
  },
  setServices(state: AdminState, payload: IService[]) {
    state.services = payload;
  },
  setService(state: AdminState, payload: IService) {
    const services = state.services.filter((service: IService) => service.id !== payload.id);
    services.push(payload);
    state.services = services;
  },
  setSenderBans(state: AdminState, payload: ISenderBan[]) {
    state.moSenderBans = payload;
  },
  setSettings(state: AdminState, payload: ISetting[]) {
    state.settings = payload;
  },
};
