import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '@/store/state';
import { mutations } from '../mutations';
import { AdminState } from '../state';

const {commit} = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetService = commit(mutations.setService);
export const commitSetServices = commit(mutations.setServices);
export const commitSetSenderBans = commit(mutations.setSenderBans);
export const commitSetSettings = commit(mutations.setSettings);
