export default {
  close: 'Close',
  dataIterator: {
    noResultsText: 'No matching records found',
    loadingText: 'Loading items...',
  },
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    ariaLabel: {
      sortDescending: ': Sorted descending. Activate to remove sorting.',
      sortAscending: ': Sorted ascending. Activate to sort descending.',
      sortNone: ': Not sorted. Activate to sort ascending.',
    },
    sortBy: 'Sort by',
  },
  dataFooter: {
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page',
    pageText: '{0}-{1} of {2}',
  },
  datePicker: {
    itemsSelected: '{0} selected',
  },
  noDataText: 'No data available',
  carousel: {
    prev: 'Previous visual',
    next: 'Next visual',
  },
  calendar: {
    moreEvents: '{0} more',
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)',
  },
  mainMenu: 'Main menu',
  dashboard: 'Dashboard',
  profile: 'Profile',
  changePassword: 'Change Password',
  admin: 'Admin',
  users: 'Users',
  services: 'Services',
  service: 'Service',
  allowedSenders: 'Allowed senders',
  callbackUrl: 'Callback url',
  callbackToken: 'Token',
  callbackTokenHeader: 'Token header',
  logout: 'Logout',
  collapse: 'Collapse',
  localeChange: 'Русский',
  login: 'Login',
  password: 'Password',
  forgotPassword: 'Forgot your password?',
  authorize: 'Login',
  incorrectAuth: 'Incorrect login or password',
  deleteUsersConfirmation: 'Are you sure you want to delete selected users?',
  name: 'Name',
  email: 'Email',
  fullName: 'Full Name',
  isActive: 'Is Active',
  passBadMessages: 'Pass bad messages',
  currentlyPassBadMessages: 'currently pass',
  currentlyNotPassBadMessages: 'currently not pass',
  currentlyIgnoreUserBans: 'currently ignore',
  currentlyNotIgnoreUserBans: 'currently not ignore',
  title: 'Name',
  externalName: 'External name',
  isSuperuser: 'Is Superuser',
  userIsActive: 'User is active',
  userIsSuperuser: 'User is superuser',
  actions: 'Actions',
  manageUsers: 'Manage Users',
  createUser: 'Create User',
  edit: 'Edit',
  editProfile: 'Edit Profile',
  delete: 'Delete',
  welcome: 'Welcome',
  currentlyActive: 'currently active',
  currentlyNotActive: 'currently not active',
  currentlySuperuser: 'currently is a superuser',
  currentlyNotSuperuser: 'currently is not a superuser',
  setPassword: 'Set Password',
  confirmPassword: 'Confirm Password',
  cancel: 'Cancel',
  reset: 'Reset',
  save: 'Save',
  images: 'Images',
  allowed: 'Allowed',
  notAllowed: 'Not allowed',
  yes: 'Yes',
  no: 'No',
  editUser: 'Edit User',
  user: 'User',
  proxyHost: 'Proxy host',
  proxyReportHost: 'Reports proxy host',
  deleteServicesConfirmation: 'Are you sure you want to delete selected services?',
  manageServices: 'Manage Services',
  createService: 'Create Service',
  editService: 'Edit Service',
  messages: 'Messages',
  statistics: 'Statistics',
  messagesStatistics: 'Messages statistics',
  sendMessage: 'Send message',
  send: 'Send',
  created: 'Created',
  error: 'Error',
  status: 'Status',
  messageText: 'Message text',
  imageUrl: 'Image url',
  imagesAllowed: 'Images allowed',
  isEmail: 'Is email',
  receivers: 'Receivers',
  receiversHint: 'Receivers phone numbers in E.164 format delimited by newline',
  receiversFile: 'Receivers list in file',
  receiversFileHint:
    '.xlsx or .csv format, no header column, one number per row in first column, numbers in E164 format',
  sender: 'Sender',
  receiver: 'Receiver',
  searchByPhoneNumber: 'Search by phone number',
  receiverPhone: 'Receiver phone number',
  countryCode: 'Country code',
  chooseService: 'Choose service',
  management: 'Management',
  manageMessages: 'Manage messages',
  moSenderBans: 'MO sender bans',
  manageSenderBans: 'Manage sender bans',
  phoneNumber: 'Phone number',
  banDuration: 'Ban duration',
  createSenderBan: 'Ban sender',
  manageSettings: 'Manage settings',
  settings: 'Settings',
  saveSettings: 'Save settings',
  key: 'Key',
  value: 'Value',
  type: 'Type',
  ban: 'Ban',
  commentary: 'Commentary',
  new: 'New',
  queued: 'Queued',
  sent: 'Sent',
  delivered: 'Delivered',
  read: 'Read',
  thisMonth: 'This month',
  period: 'Period',
  serviceCode: 'Service code',
  date: 'Date',
  timezone: 'Timezone',
  scheduledDate: 'Scheduled date',
  newsletter: 'Newsletter',
  run: 'Run',
  runNewsletter: 'Run newsletter',
  editNewsletter: 'Edit newsletter',
  manageNewsletters: 'Manage newsletters',
  isTZConsidered: 'Is tz considered',
  deleteNewsletterConfirmation: 'Are you sure you want to delete selected newsletters?',
  messageId: 'Message id',
  newslettersMessages: 'Newsletters messages',
  newslettersName: 'Newsletters name',
  blacklists: 'Blacklist',
  manageBlacklists: 'Manage blacklists',
  createBlacklist: 'Create blacklist',
  blacklistedAddresses: 'Blacklisted addresses',
  editBlacklist: 'Edit blacklist',
  deleteBlacklistsConfirmation: 'Are you sure you want to delete selected blacklists?',
  address: 'Address',
  addresses: 'Addresses',
  addressesFileHint: 'Receivers phone numbers in E.164 format delimited by newline',
  addressesFile: 'Addresses list in file',
  manageSeqSends: 'Manage sequental sending',
  createSeqSend: 'Create sequental sending',
  deleteSeqSendConfirmation: 'Are you sure you want to delete sequental sending?',
  seqSends: 'Sequental sending',
  selected: 'Selected',
  back: 'Back',
  continue: 'Continue',
  notSet: 'Not set',
  setTime: 'Set time',
  formatHHMMSS: 'Format: hh:mm:ss',
  selectServices: 'Select services',
  requiredField: '*Required field',
};
