declare global {
  interface Window {
    VUE_APP_DOMAIN: string;
    VUE_APP_KEYCLOAK_URL: string;
    VUE_APP_REALM: string;
    VUE_APP_CLIENTID: string;
  }
}

export const apiUrl = `https://${window.VUE_APP_DOMAIN}`;
export const appName = process.env.VUE_APP_NAME;
