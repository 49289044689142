import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { localize } from 'vee-validate';
import { required, email, confirmed, required_if, size } from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import ru from 'vee-validate/dist/locale/ru.json';


// Add the required rule
extend('required', required);
extend('required_if', required_if);
extend('email', email);
extend('confirmed', confirmed);
extend('size', size);

// Install English and Russian locales.
localize({
  en,
  ru,
});

// Set initial locale
localize(
  localStorage.getItem('lang') || 'en',
);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
